import { render, staticRenderFns } from "./Specification.vue?vue&type=template&id=2beb7513&scoped=true"
import script from "./Specification.vue?vue&type=script&lang=js"
export * from "./Specification.vue?vue&type=script&lang=js"
import style0 from "./Specification.vue?vue&type=style&index=0&id=2beb7513&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2beb7513",
  null
  
)

export default component.exports
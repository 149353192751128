export const state = () => ({
  order: {}
})

export const getters = {
  order: state => {
    return state.order;
  },
  payment: state => {
    return state.order.payment;
  },
  isPaid: state => {
    return state.order.status === 'paid';
  },
  isCancelled: state => {
    return ['cancelled', 'cancel'].includes(state.order.status?.name);
  },
  waitingForPayment: state => {
    return ['waiting', 'waiting_for_payment'].includes(state.order.status?.name);
  },
  isProcessing: state => {
    return state.order.status === 'processing';
  },
  payment_methods: state => {
    return state.order.payment_methods;
  }
}

export const mutations = {
  SET_ORDER(state, data) {
    state.order = data
  }
}

export const actions = {
  fetch({ commit }, id) {
    return this.$axios.$get(`/api/orders/${id}`).then(({ data }) => {
      commit("SET_ORDER", data);
    });
  },
  revive({ commit }, { id, token }) {
    return this.$axios.$get(`/api/r/orders/${id}?token=${token}`).then(({ data }) => {
      commit("SET_ORDER", data);
    });
  },
  clear({ commit }) {
    commit("SET_ORDER", {});
  },
}


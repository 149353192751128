import { isWechat } from '~/utils/browser';

export default function ({ app, redirect, route }) {
  const targetPath = process.env.WECHAT_REDIRECT_PATH || '/mp';
  if (process.server) {
    if (!isWechat() && route.path === targetPath)
      return redirect(route.query.from || '/');

    return;
  }
  
  const loc = app.$cookies.get('loc');
  const restrictedRegions = (process.env.WECHAT_RESTRICTED_REGIONS || 'cn').split(',');
  
  
  if (!(restrictedRegions.includes(loc) && isWechat())) return;
  if (route.path === targetPath) return;
  
  // 將當前路徑作為參數傳遞給 mp 頁面
  return redirect(`/${targetPath}?from=${encodeURIComponent(route.fullPath)}`);
}
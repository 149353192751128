import axios from 'axios';
import { isWechat } from '~/utils/browser';

export default function ({ app, redirect }, inject) {
  const locale = async () => {
    const url = "https://www.cloudflare.com/cdn-cgi/trace";
    try {
      const { data } = await axios.get(url);
      const json = data.split("\n").reduce((obj, pair) => {
        pair = pair.split("=");
        return (obj[pair[0]] = pair[1]), obj;
      }, {});

      const actualLoc = json.loc?.toLowerCase();

      // 從環境變量獲取受限制的地區列表
      const restrictedRegions = (process.env.WECHAT_RESTRICTED_REGIONS || 'cn').split(',');
      
      // 檢查是否為受限制地區且使用微信瀏覽器
      if (restrictedRegions.includes(actualLoc) && isWechat()) {
        const currentPath = app.router.currentRoute.fullPath;
        return redirect((process.env.WECHAT_REDIRECT_PATH || '/mp')+(currentPath ? `?from=${encodeURIComponent(currentPath)}` : ''));
      }

      const key = "loc";
      if (app.$cookies.get(key)) {
        app.i18n.setLocale(app.$cookies.get(key));
        return;
      }

      const locale = json.loc && json.loc.toLowerCase() === "hk" ? "hk" : "mo";
      app.i18n.setLocale(locale);
      app.$cookies.set(key, locale);
    } catch (error) {
      console.error("獲取地區信息失敗:", error);
      app.i18n.setLocale("mo");
      app.$cookies.set("loc", "mo");
    }
  };

  inject('locale', locale);
}
import Vue from "vue";

import { Popup, Lazyload } from "vant";
// loop to use
[Popup, Lazyload].forEach((component) => {
  Vue.use(component);
});

import VerifyTelField from "@/components/forms/VerifyTelField.vue";
Vue.component('VerifyTelField', VerifyTelField)

import VueObserveVisibility from "vue-observe-visibility";
Vue.use(VueObserveVisibility);

import Textarea2 from "@/components/vendor/Textarea2";
Vue.component("Textarea2", Textarea2);

import Modal from "@luminexs/components/modal/Modal.vue";
Vue.component("Modal", Modal);

import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
Vue.component("DatePicker", DatePicker);

// import View from '@/components/vendor/View'
// Vue.component('vView', View)

import AppView from "@/components/app/AppView";
Vue.component("AppView", AppView);

import Field from "@/components/vendor/Field";
Vue.component("vField", Field);

import FieldWithLabel from "@luminexs/components/form/FieldWithLabel.vue";
Vue.component("FieldWithLabel", FieldWithLabel);

import CopyButton from '@/components/button/CopyButton.vue';
Vue.component('CopyButton', CopyButton);

import Space from "@/components/vendor/Space";
Vue.component("Space", Space);

import TopbarBack from "@/components/TopbarBack";
Vue.component("TopbarBack", TopbarBack);

import tip from "@/components/tips/tip";
Vue.component("vTip", tip);

import Echo from "laravel-echo";


import { useWindowSize } from "@vueuse/core";

function setWindowHeight() {
  const { height } = useWindowSize();
  document.documentElement.style.setProperty(
    "--app-height",
    height.value + "px"
  );
}

function isWebview() {
  const useragent = navigator.userAgent;
  const rules = [
    "WebView",
    "(iPhone|iPod|iPad)(?!.*Safari/)",
    "Android.*(wv|.0.0.0)",
  ];
  const regex = new RegExp(`(${rules.join("|")})`, "ig");
  return Boolean(useragent.match(regex));
}

function scrollhack() {
  let last_known_scroll_position = 0;
  let elements = "input,textarea,select";
  $(document)
    .on("focus", elements, function () {
      last_known_scroll_position = window.scrollY;
    })
    .on("blur", elements, function () {
      window.scroll({
        top: last_known_scroll_position,
        left: 0,
        behavior: "smooth",
      });
    });
}

export default ({ app }, inject) => {
  // app.provide('objectToFormData', window.objectToFormData)
  if (process.client) {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      dataLayer.push(arguments);
    }
    gtag("js", new Date());
    gtag("config", process.env.GOOGLE_ADSENSE_ID);

    require("pusher-js");

    Vue.use({
      install(Vue, options) {
        
        

        Vue.prototype.$echo = new Echo({
          broadcaster: "pusher",
          key: process.env.PUSHER_KEY,
          authEndpoint: `${process.env.API_URL}/api/me`,
          cluster: process.env.PUSHER_CLUSTER,
          encrypted: true,
          enabledTransports: ['ws', 'wss'],
          authorizer: (channel) => {
            return {
              authorize: (socketId, callback) => {
                app.$axios.post('/api/broadcasting/auth', {
                  socket_id: socketId,
                  channel_name: channel.name,
                })
                  .then((data) => {
                    callback(false, data.data);
                  })
                  .catch((error) => {
                    callback(true, error);
                  });
              }
            }
          }
        });
      },
    });

    setWindowHeight();
    window.addEventListener("resize", setWindowHeight);


    const color = app.store.getters["app/color"];

    document.documentElement.style.setProperty('--color-primary', color['primary']);
    document.documentElement.style.setProperty('--color-secondary', color['secondary']);

  }

  app.mounted = function () {
    // add resize event to this.$el

    if (isWebview()) {
      scrollhack();
    }

    // this.$el.addEventListener('resize', () => {
    //   console.log('this.$el resize');
    // })
    // console.log(navigator);
    if (
      navigator.userAgent.indexOf("AlipayClient") > -1 ||
      navigator.userAgent.indexOf("mPaaS") > -1
    ) {
      // add script to head
      const script = document.createElement("script");
      script.src = "https://appx/web-view.min.js";
      document.head.appendChild(script);

      // set mini app
      this.$store.commit("app/SET_MINI_APP", "mpay");
    }
  };
};



export const state = () => ({
  type: null,
  categories: [],
  typelist: [],
  expired_at: null,
  banners: [],
  mainnavs: [],
  lang: [],
  info: {},
  tips: {},
  imgs: {},
  payment_gateway: [],
  transfer_methods: [],
  mini_app: null,
  catalogs: [],
  recommend: [],
  goods: {},
  isSafari: false,
  fetching: false,
  locales: [],
  service: {},
  color: {
    'primary': '#522e90',
    secondary: '#8d5ae5',
    chicago: '#555555',
    'light-gray': '#f5f5f5',
    'dove-gray': '#616161',
    'desert-storm': '#F8F8F8',
  }
});

export const getters = {
  inMpayMiniApp({ mini_app }) {
    return mini_app === "mpay";
  },

  customer_service({ service }) {
    return service.customer
  },

  color({ color }) {
    return color;
  },

  info({ info }) {
    return info;
  },

  imgs({ imgs }) {
    return imgs;
  },

  tips({ tips }) {
    return tips;
  },

  locales({ locales }) {
    return locales;
  },

  transfer_methods({ transfer_methods }) {
    return transfer_methods;
  },

  payment_gateway({ payment_gateway }) {
    return payment_gateway;
  },

  type({ type }) {
    return type;
  },

  categories(state) {
    return state.categories;
  },

  typelist({ typelist }) {
    return typelist;
  },

  banners({ imgs }) {
    return imgs.banners;
  },

  qrcode({ imgs }) {
    return imgs.qrcode;
  },

  wx({ imgs }) {
    return imgs.wx;
  },

  mainnavs({ mainnavs }) {
    return mainnavs;
  },

  lang({ lang }) {
    return lang;
  },

  catalogs({ catalogs }) {
    return catalogs;
  },

  recommend({ recommend }) {
    return recommend;
  },

  goods({ goods }) {
    return goods;
  },

  isSafari({ isSafari }) {
    return isSafari;
  },

  navs({ categories, typelist }) {
    const group = categories.reduce((acc, cur) => {
      const type = cur.type;
      if (!acc[type]) acc[type] = [];
      acc[type].push(cur);
      return acc;
    }, {});

    // map to array
    const list = typelist.map((type) => {
      return {
        type: type.text,
        categories: group[type.id],
      };
    });
    // filter empty categories
    return list.filter((item) => item.categories);
  },

  fetching({ fetching }) {
    return fetching;
  }
};

export const mutations = {
  SET_FETCHING(state, data) {
    state.fetching = data;
  },

  SET_CATEGORIES(state, data) {
    state.categories = data;
  },

  // SET_GOODS(state, data) {
  //   state.goods = data;
  // },

  INIT(state, data) {
    state.typelist = data.typelist;
    state.mainnavs = data.navs;
    state.lang = data.lang;
    state.info = data.info;
    state.tips = data.tips;
    state.imgs = data.imgs;
    state.color = data.color;
    state.payment_gateway = data.payment_gateway;
    state.transfer_methods = data.transfer_methods;
    state.catalogs = data.catalogs;
    state.locales = data.locales;
    state.service = data.service;
  },

  SET_MINI_APP(state, data) {
    state.mini_app = data;
  },

  SET_CATALOGS(state, data) {
    state.catalogs = data;
  },
  SET_RECOMMEND(state, data) {
    state.recommend = data;
  },
  ADD_RECOMMEND(state, data) {
    state.recommend.push(data);
  },
  SET_SAFARI(state, data) {
    state.isSafari = data;
  },
};

export const actions = {
  fetchCategories({ commit }) {
    commit("SET_FETCHING", true);
    return this.$axios.get("/api/categories")
      .then(({ data }) => {
        commit("SET_FETCHING", false);
        commit("SET_CATEGORIES", data.data);
      });
  },
  init({ commit }) {
    return this.$axios.get("/api/init").then(({ data }) => {
      commit("INIT", data.data);
    });
  },
  fetchCatalogs({ commit }) {
    return this.$axios.get("/api/catalogs")
      .then(({ data }) => {
        commit("SET_CATALOGS", data.data);
      });
  },
  // async fetchRecommend({ state, commit }) {
  //   let recommend = {};
  //   for (let item of state.catalogs) {
  //     let { data } = await this.$axios.get(`/api/catalogs/${item.no}/recommend`);
  //     recommend[item.no] = data.data;
  //   }
  //   commit("SET_RECOMMEND", recommend);
  // },

  setRecommend({ commit }, { no, items }) {
    commit("ADD_RECOMMEND", {
      no, items
    });
  },

  
};

const middleware = {}

middleware['redirect'] = require('../middleware/redirect.js')
middleware['redirect'] = middleware['redirect'].default || middleware['redirect']

middleware['register'] = require('../middleware/register.js')
middleware['register'] = middleware['register'].default || middleware['register']

middleware['registered'] = require('../middleware/registered.js')
middleware['registered'] = middleware['registered'].default || middleware['registered']

export default middleware

// export default async function ({ $axios, $auth }) {
//     if (!$auth.loggedIn) {
//         return
//     }

//     const authStrategy = $auth.strategy.name;
//     if (authStrategy != 'local') {
//         const token = $auth.getToken(authStrategy).substr(7)
//         await $axios.$post(`/api/login/${authStrategy}`, { token })
//             .then(({ data }) => {
//                 $auth.setToken('local', "Bearer " + data.token);
//                 setTimeout(async () => {
//                     $auth.setStrategy('local')
//                     setTimeout(async () => {
//                         await $auth.fetchUser()
//                     })
//                 })
//             })
//     }
// }

export default async ({ $auth, i18n, store, route, app, localePath }) => {
  // try {
  //   if (route.query.access_token) {
  //     // get auth.strategy
  //     const authStrategy = $auth.strategy.name;
  //     // set token
  //     $auth.setToken(authStrategy, "Bearer " + route.query.access_token);
  //     // fetch user
  //     await $auth.fetchUser();
  //     // redirect to last route
  //     // const redirect = $auth.$storage.getUniversal('redirect')
  //     // console.log(redirect);
  //     // if (redirect) {
  //     //   $auth.$storage.setUniversal('redirect', null)
  //     //   app.router.push(redirect)
  //     // } else {
  //     //   app.router.push({ path: '/', query: [] })
  //     // }
  //   }
  // } catch (e) {
  //   console.error(e);
  //   // clear all auth storage
  //   $auth.reset();

  //   // clear all cookie
  //   // $auth.$storage.setUniversal('redirect', null)

  //   // Remove query params to avoid errors when reloading the page and
  //   // app.router.push({ path: route.path, query: [] })
  // }

  var redirect = $auth.$storage.options.redirect;
  for (var key in redirect) {
    redirect[key] = localePath(redirect[key]);
  }

  

  $auth.$storage.options.redirect = redirect;
};
